<template>
<loader :loading="loading">
    <columns>
        <column class="is-4">
            <select-input required :error="$root.errors.type" v-model="schedule.type" classes="is-medium is-rounded"
                :items="types" description="Select the event type you would like to subscribe to.">
                Type
            </select-input>
        </column>
        <column>
            <data-selector multiple v-model="schedule.events" required :error="$root.errors.events"
                tag-class="is-medium is-rounded" delete-button-class="is-medium is-rounded is-danger"
                classes="is-medium is-rounded has-addons" :items="events"
                description="Subscribe to one or more events.">
                Events
                <template #right>
                    <action-button v-if="events.length !== schedule.events.length" class="is-rounded is-medium"
                        @click="selectAllEvents">
                        Select All
                    </action-button>
                    <action-button v-if="events.length === schedule.events.length" class="is-rounded is-medium"
                        @click="deselectAllEvents">
                        Deselect All
                    </action-button>
                </template>
            </data-selector>
        </column>
    </columns>
    <columns>
        <column>
            <select-input required :error="$root.errors.schedule" v-model="schedule.schedule"
                classes="is-medium is-rounded" :items="schedules">
                Schedule
            </select-input>
        </column>
        <column>
            <columns>
                <column v-if="schedule.schedule && schedule.schedule !== 'immediate'">
                    <date-picker v-model="schedule.time" time no-calendar date-format=" " alt-date-format=" "
                        classes="is-medium is-rounded" :items="schedules">
                        Time
                    </date-picker>
                </column>
                <column v-if="schedule.schedule === 'weekly'">
                    <select-input v-model="schedule.day_of_week" classes="is-medium is-rounded" :items="week_days">
                        Day of the Week
                    </select-input>
                </column>
                <column v-if="schedule.schedule === 'monthly'">
                    <select-input v-model="schedule.day_of_month" classes="is-medium is-rounded"
                        :items="daysInTheMonth">
                        Day of the Month
                    </select-input>
                </column>
            </columns>
        </column>
    </columns>

    <columns>
        <column>
            <submit-button :working="working" class="is-rounded is-medium" @submit="updateAlert">Save
            </submit-button>
        </column>
    </columns>
</loader>
</template>
<script>
import range from 'lodash/range'
import { alert as backend } from '@/api'

export default {

    data: () => ({
        loading: true,
        working: false,
        events: [
            { value: 'failed', label: 'Failed' },
            { value: 'passed', label: 'Passed' },
            { value: 'complete', label: 'Complete' },
            { value: 'overdue', label: 'Overdue' },
            { value: 'in progress', label: 'In Progress' },
        ],
        types: [
            { value: 'checklist', label: 'Checklist', events: ['failed', 'pass'] },
            { value: 'work_order', label: 'Work Order', events: ['failed', 'pass', 'overdue', 'completed'] },
        ],
        schedules: [
            { value: 'immediate', label: 'Immediate' },
            { value: 'daily', label: 'Daily' },
            { value: 'weekly', label: 'Weekly' },
            { value: 'monthly', label: 'Monthly' },
        ],
        week_days: [
            { value: 1, label: 'Monday' },
            { value: 2, label: 'Tuesday' },
            { value: 3, label: 'Wednesday' },
            { value: 4, label: 'Thursday' },
            { value: 5, label: 'Friday' },
            { value: 6, label: 'Saturday' },
            { value: 7, label: 'Sunday' },
        ],
        schedule: {
            id: '',
            type: '',
            events: [],
            schedule: '',
            time: '08:00',
            day_of_week: 1,
            day_of_month: 1
        }
    }),

    async beforeCreate() {
        await backend.load(this.$route.params.alert, ({data}) => {
            this.schedule.id = data.id
            this.schedule.type = data.type
            this.schedule.schedule = data.schedule.schedule
            this.schedule.time = data.schedule.time
            this.schedule.day_of_week = data.schedule.day_of_week
            this.schedule.day_of_month = data.schedule.day_of_month
            this.schedule.events = this.events.filter(event => data.events.includes(event.value))
        }, () => {})

        this.loading = false
    },

    methods: {
        selectAllEvents() {
            this.schedule.events = this.events
        },
        deselectAllEvents() {
            this.schedule.events = []
        },
        updateAlert() {
            this.working = true
            let schedule = { ...this.schedule }
            schedule.events = schedule.events.map(event => event.value)
            backend.update(schedule, () => {
                this.working = false
                this.$toast.success('Alert Created')
            }, () => this.working = false)
        }
    },

    computed: {
        daysInTheMonth() {
            return range(28).map(item => ({ value: item + 1, label: item + 1 }))
        }
    }

}
</script>